import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  hide,
  offset,
  shift,
} from '@floating-ui/dom';

type TooltipDirectiveEl = HTMLElement & {
  _tooltipEl?: HTMLDivElement | null;
  _showTooltip?: (() => Promise<void>) | null;
  _inputFocusOut?: (() => void) | null;
  _hideTooltip?: (() => void) | null;
  _cleanup?: (() => void) | null;
};

const createTooltipEl = (text: string) => {
  const tooltipEl = document.createElement('div');
  tooltipEl.id = 'tooltip';
  tooltipEl.className =
    'z-[100] w-max max-w-sm rounded bg-charcoal-2 p-4 text-sm text-charcoal-9 absolute top-0 left-0';
  tooltipEl.innerText = text;

  return tooltipEl;
};

const createArrowEl = () => {
  const arrowEl = document.createElement('div');
  arrowEl.id = 'arrow';
  arrowEl.className = 'absolute';

  return arrowEl;
};

const getText = (el: TooltipDirectiveEl) =>
  el instanceof HTMLInputElement ? el.value : el.textContent || '';

const hasOverflow = (el: TooltipDirectiveEl) => {
  const hasOverflow =
    el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;

  if (el instanceof HTMLInputElement) {
    const isFocused = document.activeElement === el;
    return isFocused ? false : hasOverflow;
  }

  return hasOverflow;
};

const showTooltip = async (el: TooltipDirectiveEl) => {
  if (el._tooltipEl) {
    return;
  }

  const tooltipTitleEl = document.getElementById('tooltipTitle')!;
  const tooltipEl = createTooltipEl(getText(el));
  const arrowEl = createArrowEl();
  tooltipEl.appendChild(arrowEl);

  el._tooltipEl = tooltipEl;

  tooltipTitleEl.appendChild(el._tooltipEl);

  const updatePosition = async () => {
    const { x, y, placement, middlewareData } = await computePosition(
      el,
      el._tooltipEl!,
      {
        placement: 'bottom-end',
        middleware: [
          offset(8),
          flip(),
          shift({ rootBoundary: 'viewport' }),
          arrow({ element: arrowEl, padding: 10 }),
          hide(),
        ],
      },
    );

    Object.assign(el._tooltipEl!.style, {
      left: `${x}px`,
      top: `${y}px`,
      visibility: middlewareData.hide?.referenceHidden ? 'hidden' : 'visible',
    });

    if (middlewareData.arrow) {
      const { x: arrowX, y: arrowY } = middlewareData.arrow;
      Object.assign(arrowEl.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
      });
    }

    el._tooltipEl!.setAttribute('data-floating-placement', placement);
  };

  el._cleanup = autoUpdate(el, el._tooltipEl!, updatePosition);
};

const hideTooltip = (el: TooltipDirectiveEl) => {
  el._tooltipEl?.remove();
  el._tooltipEl = null;

  el._cleanup?.();
  el._cleanup = null;
};

const onMounted = (el: TooltipDirectiveEl) => {
  const tooltipText = getText(el);
  if (!hasOverflow(el) || !tooltipText) {
    destroyTooltip(el);
    return;
  }

  if (!el._showTooltip || !el._hideTooltip) {
    el._showTooltip = () => showTooltip(el);
    el._hideTooltip = () => hideTooltip(el);

    el.addEventListener('mouseenter', el._showTooltip);
    el.addEventListener('mouseleave', el._hideTooltip);
  }
};

const destroyTooltip = (el: TooltipDirectiveEl) => {
  hideTooltip(el);

  if (el._showTooltip) {
    el.removeEventListener('mouseenter', el._showTooltip);
    el._showTooltip = null;
  }

  if (el._hideTooltip) {
    el.removeEventListener('mouseleave', el._hideTooltip);
    el._hideTooltip = null;
  }
};

const Title = {
  mounted: onMounted,
  unmounted: destroyTooltip,
  updated: onMounted,
};

export default Title;
