<script setup lang="ts" name="VesselsShow">
import { computed } from 'vue';

import { BASE_ITEM_TYPE } from '~/constants';

import { Item, useBaseItems } from '~/features/useBaseItem';

import BaseItem from '~/components/BaseItem.vue';
import BasePage from '~/components/BasePage.vue';
import Card from '~/components/Card.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import VesselPreview from '~/components/VesselPreview.vue';

import type { IBasePage, IVesselsShow } from '~/types';

const { vessel, page, serviceState } = defineProps<{
  page: IBasePage;
  serviceState: unknown;
  vessel: IVesselsShow | null;
}>();

const vesselDetails = computed(() => {
  if (!vessel) return [];

  return useBaseItems(
    Item.label('Name').value(vessel.name),
    Item.label('Flag Name')
      .value(vessel.flagName)
      .type(BASE_ITEM_TYPE.FLAG)
      .fallback(),
    Item.label('Built').value(vessel.builtYear).fallback(),
    Item.label('Country of Build').value(vessel.countryOfBuild).fallback(),
    Item.label('IMO').value(vessel.imo).fallback(),
  );
});

const mainData = computed(() => {
  if (!vessel) return [];

  return useBaseItems(
    Item.label('LOA').value(vessel.loa).unit('m').type('number', 1).fallback(),
    Item.label('Breadth')
      .value(vessel.breadth)
      .unit('m')
      .type('number')
      .fallback(),
    Item.label('Deadweight Summer')
      .value(vessel.deadWeightSummer)
      .unit('MT')
      .type('number')
      .fallback(),
    Item.label('Draft Summer')
      .value(vessel.draftSummer)
      .unit('m')
      .type('number', 2)
      .fallback(),
    Item.label('Gross Ton').value(vessel.grt).type('number').fallback(),
    Item.label('Net Ton').value(vessel.nrt).type('number').fallback(),
    Item.label('Bale Capacity')
      .value(vessel.bale)
      .unit('cbm')
      .type('number')
      .fallback(),
  );
});

const vesselImages = computed(() => vessel?.images || []);
</script>

<template>
  <BasePage v-bind="page" titleCapitalize>
    <ServiceCard v-bind="serviceState" styled>
      <div class="col-span-full space-y-2 lg:col-span-4 2xl:col-span-4">
        <Card title="Vessel Details" class="lg:col-span-2 lg:row-span-2">
          <div class="grid grid-cols-2 gap-4">
            <BaseItem
              v-for="item in vesselDetails"
              v-bind="item"
              :key="item.key"
            />
          </div>
        </Card>

        <Card title="Main Data" class="lg:col-span-2 lg:row-span-3">
          <div class="grid grid-cols-2 gap-4">
            <BaseItem v-for="item in mainData" v-bind="item" :key="item.key" />
          </div>
        </Card>
      </div>

      <VesselPreview
        :images="vesselImages"
        class="col-span-full lg:col-span-8 2xl:col-span-12"
      />
    </ServiceCard>
  </BasePage>
</template>
